<template>
  <div class="product">
    <van-nav-bar
      title="部门分析"
      left-text="返回"
      right-text="筛选"
      left-arrow   
      @click-left="toHome" 
      @click-right="chooseshow = true" 
    />
    <van-row class="mt10">
      <van-col span='1'></van-col>
      <van-col span="">{{params.start_time+'~'+params.end_time}}</van-col>     
    </van-row>
    <van-cell-group class="mt10">
      <div> 
        <ve-table :columns="columns" :table-data="tableData" :border-x="true" scrollWidth="98vw" :border-y="true" /> 
      </div>      
    </van-cell-group>
    <!-- <ve-pagination class="mt10" v-if="page.show"
      :layout = "page.layout"
      :total = "page.total"
      :page-size = "page.offset"
      :paging-count = "page.paging"
      @on-page-number-change = "pageChange"/> -->
      <van-pagination class="mt10" v-model="page.currentPage" :total-items="page.total" :items-per-page="page.offset" v-if="page.show" @change='pageChange' force-ellipses>
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
        <template #page="{ text }">{{ text }}</template>
      </van-pagination>
    <van-popup round v-model="chooseshow" position="top" :style="{ height: '25%' }">
      <van-field
        readonly
        clickable
        name="start_time"
        :value="params.start_time"
        label="开始时间"
        placeholder="点击选择时间"
        @click-input="stimeShow = true"
      />
      <van-field
        v-model="params.end_time"
        name="end_time"
        label="结束时间"
        @click-input="etimeShow = true"
        readonly />               
      <van-row style="margin: 16px;" gutter="20">
        <van-col span="8"><van-button round block plain size="small" type="default" native-type="button" @click="clickRest()">重置</van-button></van-col>
        <van-col span="16"><van-button title="筛选" round block size="small" type="primary" @click="clickBtn()">确定</van-button></van-col>
        <!-- <van-col span='10'>
          <van-button round block type="info" @click="clickBtn()">确定</van-button>
        </van-col>
        <van-col span='4'></van-col>
        <van-col span='10'>
          <van-button round block type="info" @click="clickRest()">重置</van-button>
        </van-col> -->
      </van-row>  
    </van-popup>    
    <!--开始年月日  --> 
    <van-popup v-model="stimeShow" position="bottom">
      <van-datetime-picker
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="stimeConfirm"
        @cancel="stimeShow = false"
      />
  </van-popup>
  <!-- 结束时间 -->
  <van-popup v-model="etimeShow" position="bottom">
      <van-datetime-picker
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="etimeConfirm"
        @cancel="etimeShow = false"
      />
  </van-popup>
  <div class="import-excel" v-if="isPhone == false" title="导出excel" @click="ToExcel" v-track="{triggerType:'click', title:'导出部门合格率',currentUrl: $route.path,behavior: '导出部门合格率', duration: new Date().getTime()}">            
    <van-icon  name="down" color="#fff" size="26" style="line-height: 50px;" />
  </div>
  <van-cell-group style="display: none;">
      <div id="exportData">
        <ve-table style="" id="table" ref="filterTable" :columns="dcColumns" :table-data="tableAll" :border-x="true" scrollWidth="720px" :border-y="true" /> 
      </div>      
    </van-cell-group>
</div>
</template>
<script>
import Vue from 'vue'
import { List, Cell, CellGroup, Tag, Col, Row, Button, Popup, Field, Toast, Dialog, NavBar, DatetimePicker, Picker,Icon,Pagination  } from 'vant'
import { mapActions } from 'vuex'
import exportTableUtil from '@/utils/exportTableUtil.js'
import XLSX from 'xlsx'
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(NavBar)
Vue.use(DatetimePicker)
Vue.use(Picker)
Vue.use(Icon)
Vue.use(Pagination)
export default {  
name: 'DataDepartments',
components: {},
data() {    
  return {   
    isPhone: this.$common.isMobilePhone(),             
    chooseshow: false, 
    stimeShow: false,
    etimeShow: false,
    page: {
      show: false,
      currentPage: 1,
      total: 0,
      page: 1,
      offset: 16,
    },
    tableData: [],
    tableAll: [],
    columns: [     
      { field: "inspect_dep_name", key: "a", title: "部门名称", align: "center" },
      { field: "hgl", key: "b", title: "合格率(%)", align: "center" },
      { field: "check_num", key: "c", title: "检测次数", align: "center" },
      { field: "qualified_num", key: "d", title: "合格次数", align: "center" }            
    ],
    dcColumns: [     
      { field: "inspect_dep_name", key: "b", title: "部门名称", align: "center" },
      { field: "check_num", key: "c", title: "检测次数", align: "center" },
      { field: "qualified_num", key: "d", title: "合格次数", align: "center" },
      { field: "hgl", key: "e", title: "合格率(%)", align: "center" },
      { field: "", key: "f", title: "", align: "" }      
    ],
    params:{
        start_time: this.$common.formatDate(new Date(2022, 0, 1)),
        end_time: this.$common.formatDate(new Date()),
        page: 1,
        offset: 8                         
      },
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(),
    };
  },
  created: function () {  
    this.onLoad() 
    this.getAll()  
  },
  mounted() {   
  },
  methods: {   
    ...mapActions('listData', ['asyncsetSampleType']),       
    onLoad () {   
      this.$get('statistics/get-inspect-dep-sta', 'api', {
        ...this.params,
        ...this.page
      }).then(res => {
        this.tableData = res.data.list
        console.log(res)
        this.page.total = Number(res.data.count)
        if (this.page.total > this.page.offset) {
          this.page.show = true
        }
      }).catch(err => {
        this.tableData = []
        this.page.total = 0
        this.$notify({ type: 'warning', message: err.message })
      })      
    },
    pageChange(val) {  
      this.page.page = val
      this.onLoad()
    },
    // 开始日期确认
    stimeConfirm(time) {    
      this.params.start_time = this.$common.formatDate(time)
      this.stimeShow = false;
    },
    // 结束日期确认
    etimeConfirm(time) {    
      this.params.end_time = this.$common.formatDate(time)
      this.etimeShow = false;      
    },    
    // 点击确定
    clickBtn() {      
      this.tableData = []
      this.page.page = 1
      this.page.currentPage = 1
      this.onLoad()      
      this.chooseshow = false
      // this.getAll()
    },   
    // 点击重置
    clickRest () {
      this.params = {
        start_time: this.$common.formatDate(new Date(2022, 0, 1)),
        end_time: this.$common.formatDate(new Date()),              
      }
      this.onLoad()
    },
    getAll() {
      var params = {
        start_time: this.params.start_time,
        end_time: this.params.end_time,
        page: 1,
        offset: 100
      }
      this.$get('statistics/get-inspect-dep-sta', 'api', params).then(res => {        
        this.tableAll = res.data.list        
      }).catch(err => {
        this.tableAll = []   
      })
    },
    // 导出excel
    ToExcel () {       
      let tbody = document.querySelector('#table tbody')
      var tr = tbody.getElementsByTagName('tr')
      tr.forEach(ele => {
        if(ele.className.indexOf('ve-table-body-tr') == -1){  
           tbody.removeChild(ele)         
        }
      })    
      let wb = XLSX.utils.table_to_book(document.querySelector('#table'))
      let elt = document.getElementById('exportData')      
      exportTableUtil.exportTable(elt, '部门分析', '部门分析', 1)
    },
    toHome() {
      this.$router.push({
        path: '/statistics'     
      })
    },            
  }          
};
</script>
<style scoped>
/* .product .van-list {
  margin-top: 66px;
} */
 .status{
  position: absolute;
  top: 30px;
  right: 0px;
  width: 80px;
  height: 80px;
  /* opacity: 40%; */
  /* transform:rotate(-45deg);
  -webkit-transform:rotate(-45deg);
  -moz-transform:rotate(-45deg); */
 }
 .border-tb{
  border-top: dashed 1px #f5f5f5;
  border-bottom: dashed 1px #f5f5f5;
  padding: 8px 0;
  margin: 8px 0;
 }
 .elasticlayer {
   height: 60%;
 }
 .tac {
   text-align: center;
 }
 .add-one {
   width: 50px;
   height: 50px;
   background: wheat;
   position: fixed;
   bottom: 100px;
   z-index: 99999;
   border-radius: 50%;
   text-align: center; 
   opacity: 0.8;
   right: 0; 
   cursor: pointer;
 }
 .red {
   color: red;
 }
 .van-cell-group--inset {
    height: 300px;
    overflow: auto;
}
.icon-del {
  position: absolute;
  right: 20px;
  z-index: 99;
  top: 10px;
}
.icon-add {
  margin-left: 20px;
}
.searchInp {
  position: fixed;
  top: 0;
  width: -webkit-fill-available;
  z-index: 9999;
}
.import-excel {
   width: 50px;
   height: 50px;
   background: #3d7cf3;
   position: fixed;
   bottom: 150px;
   z-index: 999;
   border-radius: 50%;
   text-align: center; 
   opacity: 0.8;
   right: 0; 
   cursor: pointer;
}
.van-tag {
  border-radius: 16px;
}
</style>